<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          title="供应商分项报价确认"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
          @click-right="onClickRight"
          ><template #right>
            <van-icon name="search" size="18" />
          </template>
        </van-nav-bar>
      </div>
      <!-- 排序 -->
      
    </div>
    
    <div
      class="content-s"
      :class="[!conditionsFlag ? '' : 'contentbox']"
      v-if="!show && !showexamination"
    >
      <!-- 点击排序 -->
      
      <!-- <van-loading color="#1989fa" v-if="falg" /> -->
      <van-empty
        description="没有需要审批的单据"
        v-if="formConfig.data.length == 0 && !falg && !conditionsFlag"
      />
      <van-pull-refresh
        v-model="isLoading"
        @refresh="onRefresh"
        success-text="刷新成功"
        v-if="!conditionsFlag && formConfig.data.length != 0"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
          :hasUser="false"
        >
          <list
            :formConfig="formConfig"
            ref="far"
            @checked="checkedfun"
            @result="resultfun"
            :hasUser="false"
          />
        </van-list>
      </van-pull-refresh>
    </div>
    <div
      class="footerindex"
      v-if="!conditionsFlag && formConfig.data.length != 0 && statusType == 1"
    >
      <div>
        <!-- <van-checkbox v-model="checked" @click="checkAll">全选</van-checkbox> -->
      </div>
      <van-button type="info" size="small" @click="through">通过</van-button>
    </div>
  </div>
</template>
<script>
// import utils from '@/utils/filters'
import { Toast } from "vant";
import { Dialog } from "vant";
export default {
  data() {
    return {
      falg: true,
      checked: false,
      conditionsFlag: false,
      result: [],
      show: false,
      active: 0,
      showexamination: false,
      approveBeginTime: "",
      approveEndTime: "",
      sort: "desc",
      sortType: 1,
      statusType: 1,
      compId: "",
      unit: [],
      datavalue: "",
      loading: false,
      isLoading: false,
      dataexamination: "",
      minDate: "",
      maxDate: new Date(),
      submitBeginTime: "",
      submitEndTime: "",
      pageIndex: 1,
      pageSize: 10,
      formConfig: {
        data: [],
        columns: [
          {
            prop: "goods",
            label: "招标物资",
          },
          {
            prop: "packcode",
            label: "包号",
          },
          {
            prop: "strcorpname",
            label: "供应商",
          },
          {
            prop: "quotetopic",
            label: "报价书",
          },
          {
            prop: "notifyts",
            label: "报价提交时间",
          }
        ],
        total: 0,
        url: "ecItemizedQuotationDetail",
      },
    }
    // return {
    //   falg: true,
    //   compId: "",
    //   unit: [],
    //   choose: "",
    //   checked: false,
    //   result: [],
    //   loading: false,
    //   finished: false,
    //   isLoading: false,
    //   active: 0,
    //   conditionsFlag: false,
    //   sort: "desc",
    //   sortType: 1,
    //   statusType: 1,
    //   show: false,
    //   showexamination: false,
    //   datavalue: "",
    //   dataexamination: "",
    //   pageIndex: 1,
    //   pageSize: 10,
    //   submitBeginTime: "",
    //   submitEndTime: "",
    //   approveBeginTime: "",
    //   approveEndTime: "",
    //   minDate: "",
    //   maxDate: new Date(),
    //   month: "",
    //   dataV: "",
    //   formConfig: {
    //     data: [],
    //     columns: [
    //       {
    //         prop: "stockName",
    //         label: "采购方式",
    //       },
    //       {
    //         prop: "rmbamount",
    //         label: "概算金额(元)",
    //       },
    //       {
    //         prop: "contractTypeName",
    //         label: "合同类型",
    //       },
    //       {
    //         prop: "projname",
    //         label: "项目名称",
    //       },
    //     ],
    //     total: 0,
    //     url: "procurementDetails",
    //   },
    // };
  },
  computed: {
    createBeginDate() {
      var date = new Date();
      date.setMonth(date.getMonth() - 3);
      date.toLocaleDateString();
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = y + "-" + m + "-" + d;
      return time;
    },
  },

  watch: {
    "$route.query": function(newVal) {
      if (newVal.type == 1) {
        this.falg = true;
        this.formConfig.data = [];
        this.pageIndex = 1;
        this.pageSize = 10;
        this.getList();
      }
    },
  },
  // created() {
  //   // this.getList();
  //   this.toCompInfo();
  //   this.minDate = new Date(this.createBeginDate);
  // },

  async created() {
    this.getList()
  },
  methods: {
    formatDate(date) {
      if (String(date.getMonth() + 1).length == 1) {
        this.month = "0" + (date.getMonth() + 1);
      } else {
        this.month = date.getMonth() + 1;
      }
      if (String(date.getDate()).length == 1) {
        this.dataV = "0" + date.getDate();
      } else {
        this.dataV = date.getDate();
      }
      return `${date.getFullYear()}/${this.month}/${this.dataV}`;
    },
    onConfirm(date) {
      const [start, end] = date;
      this.show = false;
      this.submitBeginTime = this.formatDate(start);
      this.submitEndTime = this.formatDate(end);
      this.datavalue = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    },
    dateReported() {
      this.show = true;
    },
    onConfirmexamination(date) {
      const [start, end] = date;
      this.showexamination = false;
      this.approveBeginTime = this.formatDate(start);
      this.approveEndTime = this.formatDate(end);
      this.dataexamination = `${this.formatDate(start)} - ${this.formatDate(
        end
      )}`;
    },
    dateReportedmexamination() {
      this.showexamination = true;
    },
    sortingbut(o) {
      this.statusType = o;
    },
    sortProperties(o) {
      this.sortType = o;
    },
    // 筛选的编制单位
    async toCompInfo() {
      let data = await this.$api.ec.toCompInfo();
      console.log('二阶段', data)
      this.unit = data.data;
    },
    reverseOrder(o) {
      if (o == 0) {
        this.compId = "";
      } else {
        this.compId = o.compId;
      }
    },
    sorting(o) {
      this.sort = o;
    },
    through() {
      if (this.$refs.far.result.length > 0) {
        Dialog.confirm({
          title: "审批",
          message: "是否同意",
        })
          .then(() => {
            this.auditProjectList();
          })
          .catch(() => {
            // on cancel
          });
      } else {
        Toast.fail("请至少选择一个审核内容");
      }
    },
    checkAll() {
      if (this.checked === true) {
        this.$refs.far.$refs.checkboxGroup.toggleAll(true);
      } else {
        this.$refs.far.$refs.checkboxGroup.toggleAll(false);
      }
    },
    checkedfun(data) {
      this.checked = data;
    },
    resultfun(data) {
      this.result = data;
    },
    conditions(o) {
      if (o == 1) {
        this.active = 0;
      } else {
        this.active = 1;
      }
      this.conditionsFlag = true;
    },
    onClickLeft() {
      if (this.show || this.showexamination) {
        this.show = false;
        this.showexamination = false;
      } else {
        window.location.replace(process.env.VUE_APP_INDEX);
      }
    },
    onClickRight() {
      this.$router.push({ name: "procurementsearch" });
    },
    // // 刷新成功
    onRefresh() {
      this.pageIndex = 1;
      this.pageSize = 10;
      this.approvalListfresh();
      // this.getList()
      // this.isLoading = false;
    },
    onLoad() {
      this.getList();
    },
    onCancel() {
      this.falg = true;
      this.conditionsFlag = false;
      this.formConfig.data = [];
      this.pageIndex = 1;
      this.getList();
    },
    onReset() {
      this.falg = true;
      (this.pageIndex = 1),
        (this.pageSize = 10),
        // (this.statusType = 1),
        (this.sort = "desc"),
        (this.sortType = 1),
        (this.submitBeginTime = ""),
        (this.submitEndTime = "");
      (this.approveBeginTime = ""), (this.approveEndTime = "");
      this.compId = "";
      this.datavalue = "";
      this.dataexamination = "";
      this.formConfig.data = [];
      this.conditionsFlag = false;
      this.getList();
    },

    async approvalListfresh() {
      const params = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };
      let data = await this.$api.ec.getItemizedQuotationList(params)
      data.data.forEach((item) => {
        item.supplierName = item.bidfilecode
      });
      this.formConfig.data = data.data
      if (data.data.length != 10) {
        this.loading = true;
        this.finished = true;
      } else {
        this.loading = false;
        this.finished = false;
      }
      // const params = {
      //   pageIndex: 1,
      //   pageSize: 10,
      //   statusType: this.statusType,
      // };
      // let data = await this.$api.ec.getList(params);
      // console.log('data2', data)
      this.isLoading = false;
      // this.formConfig.data = data.data;
      // this.formConfig.data.map((item) => {
      //   item.createUser = item.submitUserName;
      //   item.supplierName = item.projtopic;
      //   item.id = item.projectId;
      //   // item.rmbamount =utils.numberFormat(item.rmbamount,2)
      // });
    },
    // 采购预案待审批列表
    async getList() {
      const params = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        statusType: this.statusType,
      };
      let data = await this.$api.ec.getItemizedQuotationList(params)

      console.log('dataaaaaaaaaaaa', data)
      this.falg = false;
      if (data) {
        if (data.data) {
          data.data.forEach((item) => {
            item.supplierName = item.bidfilecode
          });
          if (data.data.length != 0) {
            this.pageIndex++;
            this.formConfig.data = [...this.formConfig.data, ...data.data];
          }
          if (data.data.length != 10) {
            this.loading = true;
            this.finished = true;
          } else {
            this.loading = false;
            this.finished = false;
          }
        }
        if (!data.data) {
          this.loading = true;
          this.finished = true;
        }
      }
    },
    async auditProjectList() {
      let result = this.$refs.far.result;
      let params = {
        bidNotifyId: result[0].id,
        lastupdate: result[0].lastupdate,
        audit: 1,
        leadexam: "同意",
        quotebillId: result[0].quotebillId,
        ts: result[0].ts
      }
      // if (result.length === 1) {
      //   this.choose = result[0].id.toString();
      // } else {
      //   result.map((item, index) => {
      //     if (index === 0) {
      //       this.choose = item.id;
      //     } else {
      //       this.choose = this.choose + "," + item.id;
      //     }
      //   });
      // }
      let data = await this.$api.ec.confirmQuotation(params);
      if (data) {
        this.falg = true;
        this.formConfig.data = [];
        this.pageIndex = 1;
        Toast.success("提交成功");
        this.getList();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.content-s {
  background-color: #f3f5fa;
  position: relative;
  top: 1.23rem;
  width: 100%;
  min-height: 10.66667rem;
  padding-bottom: 1.35rem;
}
</style>
